<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">A must-have E-ticket package for sightseeing in Tokyo. Select popular facilities and restaurants in Tokyo area. You can choose 3 of your favorite facilities within a week. Present the QR code on the E-ticket to easily enter the venue. </div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">How to use</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ● The Same Facility Can Only Be Used Once, And The Same Facility Can Not Be Re-Entered.<br>
          ● Ticket Validity Period: <span>90 Days</span> After Purchase.<br>
          EX: Tickets Purchased On <span>7/1</span> ⇒ <span>Ticket Validity Period:7/1~9/28.</span><br>
          ● Ticket Validity Period: After Scanning The Code At The First Facility, It Is Valid For <span>7 Days.</span><br>
          EX: If You Start To Use It On 7/1 ⇒ Validity Period: <span>7/1~7/7.</span><br>
          ●Available Facilities: You Can Use <span>3 Facilities</span> During The Valid Period.<br>
          ● Please Note That When You Purchase More Than One Ticket In A Reservation, The Starting Date Will Apply To All Tickets. <br>
          (<span>The Validity Period Is 7 Days</span>).<br>
          ● When The Ticket Has Been Used, The <span>7 Day</span> Validity Period Will Not Exceed The <span>90 Day</span> Validity Period Of The Ticket Itself.<br>
          EX: Ticket Period: <span>7/1~9/28</span>, If The Ticket Starts On <span>9/27</span>, The Valid Time Is: <span>9/27~9/28</span>.<br>
          ● Facilities That Are Unused Cannot Be Refunded.<br>
          ● Please Check The Information About Each Facility, Business Hours, And Public Holidays In Advance At The Following Links.
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i">{{title}}</div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='Notice'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='Redemption Location' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''},{'word-break': 'break-word'}]" target="_blank" :href="cItem" v-show="msg.title==='Official Website' && !contentItem.noColor">{{cItem}}<br></a>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''},{'word-break': 'break-word'}]" target="_blank" :href="cItem" v-show="msg.title==='Bus Route List' && !contentItem.noColor">{{cItem}}<br></a>
                    <p class="gfwz" v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- Notice特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hftokyo/en/havefun_title-en.png'),
      loadImg: require('@/assets/images/hftokyo/loading.png'),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hftokyo/グループ 573.png'),
          title: ['SMALL WORLDS TOKYO'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: ['Ariake Butsuryu Center, 1-3-33, Ariake,','Koto-ku, Tokyo.'] }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [{ cid: 1, text: ['https://www.smallworlds.jp/en/'] }]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['When the flight or train is delayed due to natural disasters, earthquakes, etc., if the park is closed when you arrive, no refunds will be given.'] },
                { cid: 2, text: ['If your ticket is lost or stolen, it will not be reissued.'] },
                { cid: 3, text: ['Re-entry is not permitted.'] }
              ]
            }]
        },
        {
          id: 2,
          img: require('@/assets/images/hftokyo/グループ 574.png'),
          title: ['Narita Airport To Tokyo ','Downtown Limousine Bus One Way '],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: ['①Narita International Airport Terminal 1 Arrival Lobby 1F South Exit 1','②Narita International Airport Terminal 2 Arrival Lobby 1F South Exit','③Narita International Airport Terminal 3 Main Building 1F'] }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [{ cid: 1, text: ['https://webservice.limousinebus.co.jp/web/en/Top.aspx'] }]
            },
            {
              msgId: 3,
              title: 'Bus Route List',
              content: [{ cid: 1, text: ['https://www.tripellet.com/limousine/route_list.pdf'] }]
            },
            {
              msgId: 4,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Make sure to redeem the e-ticket for the physical ticket at counters first, e-ticket is NOT valid for boarding. '] },
                { cid: 2, text: ['Number of services vary by the route, please check service schedule in advance. '] },
                { cid: 3, text: ['Travel times are subject to change due to traffic conditions enroute. Please allow ample time for your journey to and from airport.'] }
              ]
            }]
        },
        {
          id: 3,
          img: require('@/assets/images/hftokyo/グループ 575.png'),
          title: ['KEIKYU Misaki Maguro Day Trip One-Day Tour'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: ['3-26-26 Takanawa, Minato-ku, Tokyo','(Shinagawa Station)'] }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [{ cid: 1, text: ['https://www.haneda-tokyo-access.com/en/ticket/discount/maguro-day.html'] }]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Ticket only valid on the day of redemption.'] },
                { cid: 2, text: ['Tickets are nonrefundable after redemption.'] },
                { cid: 3, text: ['Business hours and closed days of participating stores are subject to change. Please check with the store before using it.'] },
                { cid: 4, text: ['In the case that the participating store is crowded, you will have to wait with general customers. This ticket has no priority.'] },
                { cid: 5, text: ['Participating stores have the right to substitute the menu or the items with other products when they are sold out. Thank you in advance for your kind understanding.<br>For details on the participating stores of Misaki Maguro Tour, please visit the link below.','<br><a style="color: rgb(33, 157, 204);border-bottom: 1px solid;word-break: break-all;" href="https://www.keikyu.co.jp/visit/otoku/otoku_maguro/">https://www.keikyu.co.jp/visit/otoku/otoku_maguro/</a>'] }
              ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hftokyo/グループ 576.jpg'),
          title: ['MOOMINVALLEY PARK Ticket & Travel Pass'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: ['1-28-1  Minamiikebukuro, Toshima-ku, Tokyo','SEIBU Railway Ikebukuro station','(SEIBU Tourist Information Center Ikebukuro)','Opposite the limited express ticket office','※ Cannot be redeemed at MOOMINVALLEY PARK'] }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [{ cid: 1, text: ['https://www.seiburailway.jp/railways/tourist/english/ticket/metsa.html'] }]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Content: SEIBU１day pass、MOOMINVALLEY PARK １day pass ticket、return-trip bus fare for MOOMINVALLEY PARK (From Hanno Station and Higashi Hanno Station)'] },
                { cid: 2, text: ['The passports of each visitor must be presented at the time of redeeming tickets.'] },
                { cid: 3, text: ['Alternatively, visitors are allowed to present a copy of the passport, photo, or residence card.'] },
                { cid: 4, text: ['No cancelation or changes can be made at the ticket exchange location'] },
                { cid: 5, text: ['The physical ticket is valid within 2 weeks after the date of excahnge.'] },
                { cid: 6, text: ['【Limited Quantity Offer】A limited number of 200 people will receive "Pin badges"<br>(While supplies last. )'] }
              ]
            }
          ]
        },
        // {
        //   id: 5,
        //   img: require('@/assets/images/hftokyo/グループ 577.png'),
        //   title: ['KOBE FARBRETON Sinjuku Coupon JPY3000'],
        //   message: [
        //     {
        //       msgId: 1,
        //       title: 'Redemption Location',
        //       content: [{ cid: 1, text: ['3-20-8 Shinjuku, Shinjuku City, Tokyo'] }]
        //     },
        //     {
        //       msgId: 2,
        //       title: 'Official Website',
        //       content: [{ cid: 1, text: ['https://kobe-farbreton.com/'] }]
        //     },
        //     {
        //       msgId: 3,
        //       title: 'Notice',
        //       isDecimal: true, //是否启用ul的数字前缀
        //       content: [
        //         { cid: 1, text: ['You can enjoy a 3,000 yen coupon.'] },
        //         { cid: 2, text: ['If there is a price difference, <br>the difference needs to be paid on site.'] },
        //         { cid: 3, text: ['Please understand that there may be cases that the ticket cannot be used because the store is full.'] }
        //         ]
        //     }
        //   ]
        // },
        {
          id: 6,
          img: require('@/assets/images/hftokyo/グループ 578.png'),
          title: ['Nonotori Coupon JPY3000'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '●新宿店',
                '3-20-8 Shinjuku, Shinjuku City, Tokyo',
                '●日本橋',
                '6F GEMS Nihonbashi,1-9-19, Kajicho, Chiyoda City, Tokyo'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['野乃鳥 KOBE YAKITORI STAND新宿店'],noColor:true },
                { cid: 2, text: ['https://nonotory.jp/stores/honten/'] },
                { cid: 3, text: ['野乃鳥 日本橋'],noColor:true },
                { cid: 4, text: ['https://nonotory.jp/stores/%e9%87%8e%e4%b9%83%e9%b3%a5-%e6%97%a5%e6%9c%ac%e6%a9%8b/'] }
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can enjoy a 3,000 yen coupon.'] },
                { cid: 2, text: ['If there is a price difference, the difference <br>needs to be paid on site.'] },
                { cid: 3, text: ['The 3,000 yen coupon can be used at any of the <br>following 2 stores - <br>Nonotor KOBE YAKITORI STAND Shinjuku, <br>Nonotor Nihonbashi.'] },
                { cid: 4, text: ['Extra fee for side dishes will be charged while entering the restaurant.'] },
                { cid: 5, text: ['Please understand that there may be cases that the ticket cannot be used because the store is full.'] },
                { cid: 6, text: ['Present Travel Contents APP at checkout and <br>enjoy 10% points reward. <br> Download at：<a style="color: rgb(33, 157, 204);border-bottom: 1px solid;word-break: break-all;" href="http://onelink.to/xyx76x">http://onelink.to/xyx76x</a>'] },
                ]
            }
          ]
        },
        // 
        {
          id: 7,
          img: require('@/assets/images/hftokyo/グループ 579.png'),
          title: ['Tokyo DOME CITY Amusement Park Facility 5 Rides Ticket'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                'Business Service Counter(MEETS PORT 2F) ',
                '1-3-61 Kōraku, Bunkyo City, Tokyo 112-0004'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.tokyo-dome.co.jp/en/tourists/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Business hours and rides are subject to sudden change, please check the official website in advance.'] },
                { cid: 2, text: ["This product is for foreigners only. Passenger's passport must be presented when exchanging tickets."] },
                { cid: 3, text: ['This product is for 1 person only. When there are multiple people, please purchase one ticket for each person. (E.g. 2 tickets for 2 people).'] },
                { cid: 4, text: ['Please follow the age restrictions on each facility.'] },
                { cid: 5, text: ['Cannot be used for SENTAI LAND and limited-time facilities.'] }
                ]
            }
          ]
        },
        {
          id: 8,
          img: require('@/assets/images/hftokyo/JOYPOLIS.jpg'),
          title: ['Tokyo Daiba JOYPOLIS PASSPORT (Admission + Unlimited rides on attractions)'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '1-6-1 Daiba, Minato-ku, Tokyo 135-0091'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://tokyo-joypolis.com/language/english/index.html'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Business hours and rides are subject to sudden change, please check the official website in advance.'] }
                ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFTokyo&l=en')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-tokyo-1-week-free-pass/en', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
      .el-image {
        width: 100%;
      }
    }
    
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
        /deep/.gfwz {
          span {
            color: #FBB03B;
          }
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>